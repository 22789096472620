import React from 'react'
import { Container, Accordion, Card } from 'react-bootstrap'
import '../styles/nonTeachingFaculty.css'
import Layout from "../components/Layout"
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../components/Head';


function NonTeachingFaculty() {

    const administrativeStaffData = [
        {
          name:'Ms. Jayashri B S',
          role:'Admin'
        },
        {
            name:'Mr. Anup Bagalkot',
            role:'Accountant'
        },
        {
            name:'Mr. Lingaraju Naik',
            role:'Clerk'
        },

    ]

    const supportiveStaffData = [
        {
          name:'Mr. Veeranagouda Patil',
          role:'Supportive Staff'
        },
        {
            name:'Mr. Huligeppagouda Chikkangoudar',
            role:'Supportive Staff'
        },
        {
            name:'Mr. Ningangouda Chikkangoudar',
            role:'Supportive Staff'
        },
        {
            name:'Mr. Ningangouda Chikkangoudar',
            role:'Supportive Staff'
        },

    ]

    const driversData = [
        {
          name:'Mr. Shambappa V',
          role:'Driver'
        },
        {
            name:'Mr. Yallappa M',
            role:'Driver'
        }
    ]

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();

}, []);

  return (
    <div className='overflow-hidden '>
      <Head title="NonTeaching Faculty"/>
        <Layout>

            <Container className="px-5">
              <div className='nonTeachingFaculty' style={{marginTop:'8rem'}}>
                <header className="sectionHeader halfBorder mb-5">Non-Teaching Faculty</header>

                <div className='' >
                  
                    <Accordion defaultActiveKey="0">

                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Administrative Staff</Accordion.Header>
                            <Accordion.Body className='d-lg-flex nonTeachingCard '>
                                {administrativeStaffData.map((data,i) => {
                                    return(
                                        <Card className='d-flex justyify-content-center'>
                                            <Card.Img variant="top" src="/avatar.png" />
                                            <Card.Body className='text-center'>
                                                <Card.Title>{data.name}</Card.Title>
                                                <Card.Subtitle className="mb-2">{data.role}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    )
                                })}
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Supportive Staff</Accordion.Header>
                            <Accordion.Body className='d-lg-flex nonTeachingCard '>
                                {supportiveStaffData.map((data,i) => {
                                    return(
                                        <Card className='d-flex justyify-content-center'>
                                            <Card.Img variant="top" src="/avatar.png" />
                                            <Card.Body className='text-center'>
                                                <Card.Title>{data.name}</Card.Title>
                                                <Card.Subtitle className="mb-2">{data.role}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    )
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Drivers</Accordion.Header>
                            <Accordion.Body className='d-lg-flex nonTeachingCard '>
                                {driversData.map((data,i) => {
                                    return(
                                        <Card className='d-flex justyify-content-center'>
                                            <Card.Img variant="top" src="/avatar.png" />
                                            <Card.Body className='text-center'>
                                                <Card.Title>{data.name}</Card.Title>
                                                <Card.Subtitle className="mb-2">{data.role}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    )
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Security</Accordion.Header>
                            <Accordion.Body className='d-lg-flex nonTeachingCard '>
                                <Card>
                                    <Card.Img variant="top" src="/avatar.png" />
                                    <Card.Body className='text-center'>
                                        <Card.Title>Mr. Hanumantappa M</Card.Title>
                                        <Card.Subtitle className="mb-2">Security</Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Housekeeping</Accordion.Header>
                            <Accordion.Body className='d-lg-flex nonTeachingCard '>
                                <Card>
                                    <Card.Img variant="top" src="/avatar.png" />
                                    <Card.Body className='text-center'>
                                        <Card.Title>Mr. Fakirappa</Card.Title>
                                        <Card.Subtitle className="mb-2 ">Housekeeper</Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Gardening</Accordion.Header>
                            <Accordion.Body className='d-lg-flex nonTeachingCard '>
                                <Card>
                                    <Card.Img variant="top" src="/avatar.png" />
                                    <Card.Body className='text-center'>
                                        <Card.Title>Mr. Karabasappa</Card.Title>
                                        <Card.Subtitle className="mb-2">Gardener</Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>


                  
                </div>
              </div>
            </Container>
        </Layout>
    </div>
  )
}

export default NonTeachingFaculty
